import * as React from "react";
import { graphql, Link } from "gatsby";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { FancyBackground } from "../components/fancy-background";
import { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";

// const GOOGLE_CLIENT_ID =
//   "604896179759-ao2g2leihmp0ukgohs8d4vo1e3c85a5v.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID =
  "604896179759-32sncv1ofgtqehndoqvdkde4t7p1maai.apps.googleusercontent.com"; // for local dev

function Google({ disabled }: { disabled: boolean }) {
  return (
    <div className="w-56 mx-auto relative">
      <div
        style={{
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <div
          id="g_id_onload"
          data-client_id={GOOGLE_CLIENT_ID}
          data-context="signup"
          data-ux_mode="redirect"
          data-login_uri="http://localhost:8000/sign-up"
          // data-callback={window.handleGoogleLogin}
          data-auto_prompt="false"
        ></div>

        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="pill"
          data-theme="outline"
          data-text="continue_with"
          data-size="large"
          data-locale="en-US"
          data-logo_alignment="left"
        ></div>
      </div>
      {disabled && (
        <>
          <div
            className="absolute h-full w-full top-0 left-0 right-0 bottom-0"
            onClick={(e) => {
              // prevent default
              e.preventDefault();
            }}
          ></div>
          <div className="text-center text-gray-400 text-xs mt-2">
            You must agree to the terms of service and privacy policy to
            continue.
          </div>
        </>
      )}
    </div>
  );
}

const supabase = createClient(
  "https://ttojmypqyoarstpercic.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InR0b2pteXBxeW9hcnN0cGVyY2ljIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzMyMzk0OTUsImV4cCI6MTk4ODgxNTQ5NX0.GCh5sdOK4mekxKMlOdzJA5aa_LfaGvvNfXRlWucKsto"
);

// function Google({ disabled }: { disabled: boolean }) {
//   return (
//     <div className="w-56 mx-auto relative">
//       <div
//         style={{
//           opacity: disabled ? 0.5 : 1,
//         }}
//       >
//         <button
//           className="w-full bg-white text-gray-800 font-medium rounded-lg shadow-md py-2 px-4 flex items-center justify-center"
//           onClick={() => {
//             supabase.auth.signInWithOAuth({
//               provider: "google",
//             });
//           }}
//         >
//           <span>Continue with Google</span>
//         </button>
//       </div>
//       {disabled && (
//         <>
//           <div
//             className="absolute h-full w-full top-0 left-0 right-0 bottom-0"
//             onClick={(e) => {
//               // prevent default
//               e.preventDefault();
//             }}
//           ></div>
//           <div className="text-center text-gray-400 text-xs mt-2">
//             You must agree to the terms of service and privacy policy to
//             continue.
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

const SignUpPage = () => {
  const [agreed, setAgreed] = React.useState(false);

  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
    >
      <Helmet>
        <title>Sign up – Contextie</title>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      <FancyBackground />
      <div className="w-full grid justify-items-center gap-10 pt-20 px-6 pb-20 font-inter">
        <div className="grid grid-cols-1 gap-10 lg:gap-20 bg-[#ffff] rounded-3xl shadow-2xl">
          <div className="grid grid-cols-1 gap-4 max-w-6xl px-6 py-12">
            <article className="max-w-4xl px-6">
              <h1
                className="text-3xl text-center pb-8 leading-snug dark:text-white font-medium"
                style={{
                  letterSpacing: "-0.02em",
                }}
              >
                Welcome to Contextie.
              </h1>
              <div className="grid grid-cols-1 gap-4">
                <p>
                  Contextie is an AI assistant that lives in the corner of your
                  screen, and can answer questions about what you're currently
                  doing. Kinda like ChatGPT, but it is able to look at your
                  screen to get context about your question.
                </p>
                <p>
                  You can try Contextie for free for 7 days. After that, it is
                  $15 per month.
                </p>
                <p>
                  <b>
                    Important: When you ask a question, Contextie screenshots
                    every window on your desktop, including windows that may be
                    hidden behind other windows or on other displays. The text
                    in the screenshots is sent to Contextie's servers, and
                    forwarded to OpenAI's servers, to help answer your question.
                    While this is extremely useful, it can also be dangerous
                    — for example, if one of your windows contains sensitive
                    information, such as a password or a credit card number.
                    Please close all sensitive windows when you ask Contextie a
                    question.
                  </b>
                </p>
                <div>
                  <label className="ml-2" htmlFor="agreed">
                    <input
                      type="checkbox"
                      className="mr-2 rounded-sm outline-none ring-none appearance-none focus:ring-0 focus:ring-offset-0 mb-1"
                      id="agreed"
                      checked={agreed}
                      onChange={(e) => {
                        setAgreed(e.target.checked);
                      }}
                    />
                    <b>
                      I understand that Contextie sends all text from all of my
                      windows to its and OpenAI's servers when I ask a question.
                    </b>{" "}
                    I also agree to the{" "}
                    <a
                      href="https://contextie.ai/terms-of-service"
                      className="text-blue-500 hover:text-blue-600"
                    >
                      Terms of Service
                    </a>{" "}
                    and the{" "}
                    <a
                      href="https://contextie.ai/privacy-policy"
                      className="text-blue-500 hover:text-blue-600"
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1 place-content-center items-center gap-4 pt-8 content-center">
                <Google disabled={!agreed} />
              </div>
            </article>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default SignUpPage;
